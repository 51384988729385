import request from "@/utils/axios.tool";
// 产品结构统计
export const getProductStatistics = (data) => {
  return request.post(`/roleapi/index/getProductStatistics`, data);
}; // 待办事项列表
export const getLatestReceipt = (data) => {
  return request.post(`/roleapi/index/getLatestReceipt`, data);
};
// 战绩报告
export const newAchievementReport = (data) => {
  return request.post(`/roleapi/index/newAchievementReport`, data);
};
// 排名
export const getRankingList = (data) => {
  return request.post(`/roleapi/index/getRankingList`, data);
};
// 获取下属部门或者人员战绩
export const getMilitaryExploits = (data) => {
  return request.post(`/roleapi/index/getMilitaryExploits`, data);
};

// 获取客户拜访
export const getCustomerVisit = (data) => {
  return request.post(`/roleapi/index/getCustomerVisit`, data);
};
// 获取客户相关
export const getCustomerStatistics = (data) => {
  return request.post(`/roleapi/index/getCustomerStatistics`, data);
};
// 获取客户相关
export const getThirtyArrive = (data) => {
  return request.post(`/roleapi/index/getThirtyArrive`, data);
};
// 获取荣誉
export const getHonorData = (data) => {
  return request.post(`/roleapi/index/getHonorData`, data);
};
// 获取待收款
export const newExpectedReceiptList = (data) => {
  return request.post(`/roleapi/index/newExpectedReceiptList`, data);
};
