<template>
  <div class="datacontent" ref="datacontent" v-loading="isLoading">
    <div class="title" @click="changeCompany">
      {{ company_info.name || userInfo.name }}<span>数据展板</span><img v-if="isShowChange" src="@/assets/img/qiehuan.png" alt="" />
    </div>

    <!--  -->
    <div class="big-box">
      <div class="big-box-left">
        <div class="date-select">
          <div class="day" :class="activeDate == 1 ? 'active day2' : ''" @click="changeDate(1)">
            {{ toDay == queryForm.date || !queryForm.date ? "今日" : queryForm.date }}
            <el-date-picker
              @change="timeChange"
              value-format="yyyy-MM-dd"
              class="dayselect"
              v-model="queryForm.date"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>

          <div class="month" :class="activeDate == 2 ? 'active month2' : ''" @click="changeDate(2)">
            <img v-if="activeDate == 1" src="./../../assets/img/newDataIndex/rili.png" alt="" /><img
              v-else
              src="./../../assets/img/newDataIndex/rili-white.png"
              alt=""
            />
            <el-date-picker
              v-model="queryForm.month"
              @change="reload"
              value-format="yyyy-MM"
              class="monthselect"
              type="month"
              placeholder="选择月"
            >
            </el-date-picker>
            {{ toMonth == queryForm.month ? "本月" : !queryForm.month ? "月" : queryForm.month }}
          </div>
        </div>
        <div class="topcharts">
          <div class="left">
            <div class="echarts-title">产品结构</div>
            <div class="echarts-more" @click="chanpingfenxi">详情</div>
            <div id="roundProduct"></div>
            <div class="dingwei">
              <img src="./../../assets/img/newDataIndex/left-top.png" alt="" />
              <img src="./../../assets/img/newDataIndex/right-top.png" alt="" />
              <img src="./../../assets/img/newDataIndex/left-bottom.png" alt="" />
              <img src="./../../assets/img/newDataIndex/right-bottom.png" alt="" />
            </div>
          </div>
          <div class="mid">
            <div class="tits"><span>战绩报告</span></div>
            <div class="box">
              <div class="tasktongji">
                <div class="item">
                  <div class="name">任务额</div>
                  <div class="value">{{ halfInfo.task_money || 0 }}</div>
                </div>
                <div class="item">
                  <div class="name">完成额</div>
                  <div class="value">{{ halfInfo.completed_amount || 0 }}</div>
                </div>
                <div class="item" @click="openAnticipateAmount" style="cursor: pointer">
                  <div class="name">预计到账</div>
                  <div class="value">{{ halfInfo.anticipate_amount || 0 }}</div>
                </div>
              </div>
              <div id="banyuanEcharts"></div>
              <div class="taskover-text">任务已完成</div>
              <div class="bfb flex-center">{{ halfInfo.rate }}%</div>
              <div class="daqupaiming flex-sb">
                <div class="tot">共{{ rankInfo.count }}个部门/人参与排名</div>
                <div class="le">
                  <div class="pm">
                    {{ rankInfo.depth == 0 ? "个人" : "" }}{{ rankInfo.depth == 1 ? "大区" : "" }}{{ rankInfo.depth == 2 ? "分公司" : ""
                    }}{{ rankInfo.depth == 3 ? "事业线" : "" }}{{ rankInfo.depth == 4 ? "战区" : ""
                    }}{{ rankInfo.depth == 5 ? "军团" : "" }}排名[<span>TOP{{ rankInfo.ranking }}</span
                    >]
                  </div>
                </div>
                <div class="mi" v-if="rankInfo.ranking != 1">
                  <div class="line">
                    比<el-tooltip class="item" effect="dark" :content="rankInfo.firstName" placement="top-start"
                      ><span>上一名</span></el-tooltip
                    >少<span class="money">￥{{ rankInfo.firstMoney || 0 }}</span>
                  </div>
                  <div class="line">
                    比<el-tooltip class="item" effect="dark" :content="rankInfo.secondName" placement="top-start"
                      ><span>第一名</span></el-tooltip
                    >少<span class="money">￥{{ rankInfo.secondMoney || 0 }}</span>
                  </div>
                </div>
                <div class="mi" v-else>
                  <div class="line">
                    比<el-tooltip class="item" effect="dark" :content="rankInfo.firstName" placement="top-start"
                      ><span>第二名</span></el-tooltip
                    >多<span class="money">￥{{ rankInfo.firstMoney || 0 }}</span>
                  </div>
                </div>
                <div class="ri" @click="qufenxi">
                  去分析
                  <img src="./../../assets/img/newDataIndex/indexfenxiright.png" alt="" />
                </div>
              </div>

              <!--  -->
              <div class="dingwei">
                <img src="./../../assets/img/newDataIndex/left-top.png" alt="" />
                <img src="./../../assets/img/newDataIndex/right-top.png" alt="" />
                <img src="./../../assets/img/newDataIndex/left-bottom.png" alt="" />
                <img src="./../../assets/img/newDataIndex/right-bottom.png" alt="" />
              </div>
            </div>
            <!--  -->
          </div>
        </div>
        <div class="bottomcharts flex-sb">
          <div class="zuixindaozhang">
            <div class="echarts-title">本区最新到账</div>
            <div class="table">
              <div class="th">
                <div class="td">姓名</div>
                <div class="td">类型</div>
                <div class="td">金额</div>
                <div class="td">时间</div>
              </div>
              <div class="tr-box" ref="tr-box">
                <div class="tr" v-for="(item, index) in lastReceiptList" :key="index">
                  <div class="td">{{ item.name }}</div>
                  <div class="td">{{ item.product_name }}</div>
                  <div class="td">￥{{ item.money }}</div>
                  <div class="td">{{ item.time }}</div>
                </div>
              </div>
            </div>
            <!--  -->
            <div class="dingwei">
              <img src="./../../assets/img/newDataIndex/left-top.png" alt="" />
              <img src="./../../assets/img/newDataIndex/right-top.png" alt="" />
              <img src="./../../assets/img/newDataIndex/left-bottom.png" alt="" />
              <img src="./../../assets/img/newDataIndex/right-bottom.png" alt="" />
            </div>
          </div>
          <div class="numtongji">
            <div class="bline">
              <div class="item fk"><span class="red"></span>总计</div>
              <div class="item">{{ statisticsInfo.customer_count }}</div>
              <div class="item">{{ statisticsInfo.a_count_customer }}</div>
              <div class="item">{{ statisticsInfo.call_on_count }}</div>
            </div>
            <div class="bline">
              <div class="item fk"><span class="blue"></span>人均</div>
              <div class="item">
                <div class="num">{{ statisticsInfo.avg_customer_count || 0 }}</div>
                <div class="name">意向客户</div>
              </div>
              <div class="item">
                <div class="num">{{ statisticsInfo.avg_a_count_customer || 0 }}</div>
                <div class="name">A类客户</div>
              </div>
              <div class="item">
                <div class="num">{{ statisticsInfo.avg_call_on_count || 0 }}</div>
                <div class="name">面访量</div>
              </div>
            </div>
            <img src="./../../assets/img/newDataIndex/numsborder.png" class="border" alt="" />
            <div class="bline">
              <div class="item fk"><span class="red"></span>总计</div>
              <div class="item">{{ statisticsInfo.successful_customer }}</div>
              <div class="item">{{ statisticsInfo.order_count }}</div>
              <div class="item">{{ statisticsInfo.money }}</div>
            </div>
            <div class="bline">
              <div class="item fk"><span class="blue"></span>人均</div>
              <div class="item">
                <div class="num">{{ statisticsInfo.avg_successful_customer }}</div>
                <div class="name">成交客户</div>
              </div>
              <div class="item">
                <div class="num">{{ statisticsInfo.avg_order_count }}</div>
                <div class="name">订单数</div>
              </div>
              <div class="item">
                <div class="num">{{ statisticsInfo.avg_money }}</div>
                <div class="name">到账金额</div>
              </div>
            </div>
            <!--  -->
            <div class="dingwei">
              <img src="./../../assets/img/newDataIndex/left-top.png" alt="" />
              <img src="./../../assets/img/newDataIndex/right-top.png" alt="" />
              <img src="./../../assets/img/newDataIndex/left-bottom.png" alt="" />
              <img src="./../../assets/img/newDataIndex/right-bottom.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="big-box-right">
        <div class="all_can_see_head_ry">
          <div class="honer_img_box" v-if="honorList.length != 0" style="cursor: pointer" @click="openHonor">
            <img src="../../assets/img/trophy.png" alt="" class="honer_img" v-for="(item, index) in honorList.slice(0, 4)" :key="index" />
          </div>
        </div>
        <div class="line-echarts-box">
          <div class="echarts-title">下属部门/人员战绩</div>
          <div class="fg-box"></div>
          <div id="lineEcharts"></div>
        </div>
        <div class="line-echarts-box">
          <div class="echarts-title">客户拜访</div>
          <div class="echarts-more" @click="mianfangwenxi">详情</div>
          <div class="fg-box"></div>
          <div id="lineEcharts2"></div>
        </div>
        <!--  -->
        <div class="dingwei">
          <img src="./../../assets/img/newDataIndex/left-top.png" alt="" />
          <img src="./../../assets/img/newDataIndex/right-top.png" alt="" />
          <img src="./../../assets/img/newDataIndex/left-bottom.png" alt="" />
          <img src="./../../assets/img/newDataIndex/right-bottom.png" alt="" />
        </div>
      </div>
    </div>
    <div class="line-zhexian">
      <div id="zhexian"></div>
    </div>
    <el-dialog title="选择部门" :visible.sync="isShowCompany" width="30%" :before-close="searchDialog">
      <div class="select-cont" v-loading="isbumenloading">
        <i class="el-icon-error" v-if="isfocus" @click="inputBlur"></i>
        <el-input v-model="serch_val" @focus="inputFocus" placeholder="搜索姓名/部门/电话"></el-input>
        <div class="lines">
          <div class="item" v-for="(item, index) in linePathList" :key="index" @click="select(item)">
            <div class="name">{{ item.name }}</div>
            <span class="el-icon-caret-right" v-if="index != linePathList.length"></span>
          </div>
        </div>
        <div style="height: 10px; background-color: #f2f2f2"></div>
        <div class="tits">部门</div>
        <div class="line-box">
          <div class="line-f flex-sb" v-for="(item, index) in lines" :key="index">
            <div class="left" @click="select(item)">{{ item.name }}</div>
            <div class="right" @click="checked(item)">选择</div>
          </div>
        </div>
        <div v-if="lines.length == 0" class="nos">暂时没有哦~</div>
        <div class="tits">人员</div>
        <div class="user-list">
          <div class="line-f flex-sb" v-for="(item, index) in selectUserList" :key="index" v-if="item.is_sales == 1">
            <div class="left"><i class="el-icon-s-custom"></i> {{ item.name }}</div>
            <div class="right" @click="checkedUser(item)">选择</div>
          </div>
        </div>
        <div v-if="selectUserList.length == 0" class="nos">暂时没有哦~</div>
      </div>
    </el-dialog>
    <el-dialog title="荣誉" :visible.sync="isShowHonor" width="50%" append-to-body>
      <div class="honorDialog" v-loading="isLoading">
        <div class="cont">
          <div v-for="(item, index) in honorList" :key="index" class="ite" @click="openPreview(item.honor_img)">
            <el-image class="img" :src="item.honor_img" :fit="'fill'" />
            <div>{{ item.title }}</div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div class="info_home" @click="gohome">
      <img src="@/assets/img/redhome.png" alt="" style="height: 26px; width: 28px; display: block" />
    </div>
    <el-dialog title="到账详情" append-to-body :visible.sync="isShowAnticipateAmount" width="40%">
      <el-table :data="anticipateAmountList" style="width: 100%" v-loading="isLoading">
        <el-table-column prop="customer" label="公司名称"> </el-table-column>
        <el-table-column prop="user" label="跟进人"> </el-table-column>
        <el-table-column prop="expected_arrival" label="预计到账金额"> </el-table-column>
        <el-table-column prop="to_time" label="预计到账时间"> </el-table-column>
      </el-table>
      <el-pagination :current-page.sync="antiPage" @current-change="antiPageChange" layout="prev, pager, next" :total="antiTotal">
      </el-pagination>
    </el-dialog>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { obtainUserCompany } from "@/api/User/login";
import {
  getProductStatistics,
  getLatestReceipt,
  newAchievementReport,
  getRankingList,
  getMilitaryExploits,
  getCustomerVisit,
  getCustomerStatistics,
  getThirtyArrive,
  getHonorData,
  newExpectedReceiptList,
} from "@/api/newDataIndex";
import { select, userList, getAncestorsAndSelf, searchCompany } from "../../api/User/human";

export default {
  data() {
    return {
      activeDate: 1,
      queryForm: {
        date: "",
      },
      toDay: "",
      toMonth: "",
      halfInfo: {},
      rankInfo: {},
      lastReceiptList: [],
      statisticsInfo: {},
      is_sales: 1,
      my_Honor: 0,
      isShowHonor: false,
      isLoading: false,
      isShowImage: false,
      imgUrl: "",
      company_info: {},
      userInfo: {},
      isShowCompany: false,
      serch_val: "",
      lines: [],
      role_alldata: {},
      selectUserList: [],
      linePathList: [],
      topCompany: {},
      honorList: [],
      honorType: 2,
      company_show_user_info: {},
      loading: null,
      isfocus: false,
      isShowAnticipateAmount: false,
      anticipateAmountList: [],
      antiTotal: 0,
      antiPage: 1,
      isShowChange: false,
      ishasOverCompany: false,
      depth: null,
      dontrequest: false,
      mycompany: [],
      isbumenloading: false,
    };
  },
  created() {
    let newdata = JSON.parse(localStorage.getItem("token"));
    let role_permissions = newdata.role_permissions;
    let newpower = {};
    for (let i in role_permissions) {
      if (role_permissions[i].permissions == "resources") {
        newpower = role_permissions[i];
        this.role_alldata = role_permissions[i];
      }
    }
  },
  mounted() {
    if (localStorage.getItem("user")) {
      let user = JSON.parse(localStorage.getItem("user"));
      this.is_sales = user.is_sales;
      if (user.is_company.length > 0) {
        this.isShowChange = true;
      }
    }
    obtainUserCompany().then((res) => {
      if (res.data.code == 200) {
        let list = res.data.data.is_company;
        if (list.length != 0) {
          this.isShowChange = true;
          this.ishasOverCompany = true;
          this.lines = list.map((item) => {
            return item.company;
          });
          this.mycompany = list.map((item) => {
            return item.company;
          });
          this.depth = this.lines[0]["depth"];
          if (localStorage.getItem("company_info")) {
            let info = JSON.parse(localStorage.getItem("company_info"));
            this.company_info = info;
            this.$set(this.queryForm, "company_id", info.id);
          } else {
            if (list.length == 1) {
              let info = list[0].company;
              this.company_info = info;
              this.$set(this.queryForm, "company_id", info.id);
            } else {
              this.company_info = {};
              this.$set(this.queryForm, "company_id", "");
              this.userInfo = JSON.parse(localStorage.getItem("user"));
              this.$set(this.queryForm, "search_user_id", this.userInfo.user_id);
              this.dontrequest = true;
            }
          }
          // }
        } else {
          this.company_info = {};
          this.$set(this.queryForm, "company_id", "");
          this.userInfo = JSON.parse(localStorage.getItem("user"));
          this.$set(this.queryForm, "search_user_id", this.userInfo.user_id);
          this.dontrequest = true;
        }
      }
    });
    let winWidth = "";
    if (window.innerWidth) {
      winWidth = window.innerWidth;
    } else if (document.body && document.body.clientWidth) {
      winWidth = document.body.clientWidth;
    }
    let month = new Date().getMonth() + 1;
    let day = new Date().getDate();
    this.toDay = new Date().getFullYear() + "-" + (month < 10 ? "0" + month : month) + "-" + (day < 10 ? "0" + day : day);
    this.toMonth = new Date().getFullYear() + "-" + (month < 10 ? "0" + month : month);
    this.$set(this.queryForm, "date", this.toDay);
    setInterval(() => {
      try {
        this.$refs["tr-box"].scrollTop = ~~this.$refs["tr-box"].scrollTop + 1;
      } catch (e) {
        try {
          this.$refs["tr-box"].scrollTop = 0;
        } catch (e) {}
      }
    }, 300);
    this.reload();
  },
  methods: {
    openAnticipateAmount() {
      this.isShowAnticipateAmount = true;
      this.getExpectedReceiptList();
    },
    antiPageChange() {
      this.getExpectedReceiptList();
    },
    getExpectedReceiptList() {
      newExpectedReceiptList({
        ...this.queryForm,
        page: this.antiPage,
      }).then((res) => {
        this.anticipateAmountList = res.data.data.data;
        this.antiTotal = res.data.data.total;
      });
    },
    gohome() {
      localStorage.setItem("isSelect", "1-1");
      this.$router.push("/Marketing/Account");
    },
    inputFocus() {
      if (this.isfocus) {
        return;
      }
      this.isfocus = true;
      this.selectUserList = [];
      this.lines = [];
      this.linePathList = [];
    },
    inputBlur() {
      this.isfocus = false;
      this.serch_val = "";
      this.selectUserList = [];
      this.lines = [...this.mycompany];
      let parent_id = "";
      if (this.company_info.id) {
        parent_id = this.company_info.parent_id || this.company_info.id;
      } else {
        parent_id = this.mycompany[0]["parent_id"];
      }
      this.getAncestorsAndSelf({
        id: parent_id,
      });
    },
    searchDialog(done) {
      this.inputBlur();
      done();
    },
    // 弹出改变部门的框
    changeCompany() {
      if (!this.isShowChange) {
        return;
      }
      this.isShowCompany = true;
      this.linePathList = [];
      this.selectUserList = [];
      this.serch_val = "";
      // if (this.ishasOverCompany) {
      //   return;
      // }
      let parent_id = "";
      if (this.company_info.id) {
        parent_id = this.company_info.parent_id || this.company_info.id;
      } else {
        parent_id = this.mycompany[0]["parent_id"];
      }
      this.isbumenloading = true;
      this.getAncestorsAndSelf(
        {
          id: parent_id,
        },
        (res) => {
          this.select({ ...res[res.length - 1] });
        }
      );
      // this.dontrequest = true;
    },
    checked(item) {
      this.queryForm.search_user_id = "";
      this.queryForm.company_id = item.id;
      this.userInfo = {};
      this.company_info = item;
      this.isShowCompany = false;
      this.reload();
    },
    checkedUser(item) {
      this.queryForm.search_user_id = item.id;
      this.queryForm.company_id = "";
      this.userInfo = item;
      this.company_show_user_info = this.linePathList[this.linePathList.length - 1];
      this.company_info = {};
      this.isShowCompany = false;
      this.reload();
    },
    searchCompany() {
      searchCompany({
        name: this.serch_val,
      }).then((res) => {
        this.lines = res.data.data.company;
        this.selectUserList = res.data.data.user;
      });
    },
    select(item) {
      if (item) {
        if (item.depth < this.depth) {
          this.isbumenloading = false;
          return;
        }
      }
      if (this.dontrequest) {
        this.isbumenloading = false;
        this.dontrequest = false;
        return;
      }
      select({
        is_count: true,
        is_ranking: 1,
        parent_id: (item && item.id) || this.company_info.id || this.company_show_user_info.id || "",
      }).then((res) => {
        this.isbumenloading = false;
        if (res.data.data.id) {
          this.topCompany = res.data.data;
          this.lines = [...res.data.data.data];
        } else {
          this.lines = res.data.data;
        }
        let str = "";
        for (let i in this.role_alldata.data) {
          if (this.role_alldata.data[i].permissions == "serch_userList") {
            str = this.role_alldata.data[i].permissions_id_string;
            continue;
          }
        }
        if (item && item.depth) {
          userList({
            company_id: (item && item.id) || this.company_info.id || this.company_show_user_info.id || "",
            depth: (item && item.depth) || this.company_info.depth,
            page: 1,
            permissions_id_string: str,
          }).then((res) => {
            this.selectUserList = res.data.data.data;
          });
        } else if (this.company_show_user_info.depth) {
          userList({
            company_id: this.company_show_user_info.id,
            depth: this.company_show_user_info.depth,
            page: 1,
            permissions_id_string: str,
          }).then((res) => {
            this.selectUserList = res.data.data.data;
          });
        }

        this.getAncestorsAndSelf(item);
      });
    },
    getAncestorsAndSelf(item, call) {
      getAncestorsAndSelf({
        company_id: (item && item.id) || this.company_info.id || this.company_show_user_info.id || "",
      }).then((res) => {
        this.linePathList = [...res.data.data];
        call && call(res.data.data);
      });
    },

    changeDate(i) {
      this.activeDate = i;
      if (i == 1) {
        this.$set(this.queryForm, "month", "");
      } else {
        this.$set(this.queryForm, "date", "");
      }
      // this.reload();
    },
    timeChange(e) {
      if (!e) {
        this.$nextTick(() => {
          this.$set(this.queryForm, "date", this.toDay);
          this.reload();
        });
      } else {
        this.reload();
      }
    },
    openPreview(url) {
      this.imgUrl = url;
      this.isShowImage = true;
    },
    changeHonor(v) {
      this.honorType = v;
    },
    openHonor() {
      this.isShowHonor = true;
      this.changeHonor(1);
    },
    reload() {
      localStorage.removeItem("jumpInfo");
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.9)",
      });
      this.createRoundProduct();
      this.createHaflRoundTask();
      this.createLineEcharts();
      this.getLatestReceipt();
      this.getCustomerStatistics();
      this.getgetThirtyArrive();
      getHonorData({ ...this.queryForm }).then((res) => {
        this.honorList = res.data.data;
        this.loading.close();
      });
    },
    getCustomerStatistics() {
      getCustomerStatistics({ ...this.queryForm }).then((res) => {
        this.statisticsInfo = res.data.data;
      });
    },
    getLatestReceipt() {
      getLatestReceipt({ ...this.queryForm, num: 50 }).then((res) => {
        this.lastReceiptList = res.data.data;
      });
    },
    createLineEcharts() {
      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            color: "#ffffff",
          },
        },
        grid: {
          x: 45,
          y: 45,
          x2: 5,
          y2: 25,
          borderWidth: 1,
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLabel: {
            color: "#ffffff",
          },
        },
        series: [
          {
            data: [],
            type: "bar",
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#2660fe" },
                { offset: 1, color: "#0295ff" },
              ]),
            },
          },
        ],
      };
      getMilitaryExploits({ ...this.queryForm }).then((res) => {
        let data = res.data.data;
        var lineEcharts = echarts.init(document.getElementById("lineEcharts"));
        let ob2 = { ...option };
        ob2.series[0]["data"] = data.map((item) => {
          return item.total_money;
        });
        ob2.xAxis["data"] = data.map((item) => {
          return item.name;
        });
        lineEcharts.setOption(option);
      });
      getCustomerVisit({ ...this.queryForm }).then((res) => {
        let data = res.data.data;
        var lineEcharts2 = echarts.init(document.getElementById("lineEcharts2"));
        let ob2 = { ...option };
        ob2.series[0]["data"] = data.map((item) => {
          return item.visit_count;
        });
        ob2.xAxis["data"] = data.map((item) => {
          return item.name;
        });
        lineEcharts2.setOption(option);
      });

      // 使用刚指定的配置项和数据显示图表。
    },
    getgetThirtyArrive() {
      getThirtyArrive({ ...this.queryForm }).then((res) => {
        if (res.data.code == 200) {
          let one_data = [];
          let line_data = [];
          let day_arr = []; //底部
          let arr = res.data.data;

          let zhexian = this.$echarts.init(document.getElementById("zhexian"));
          zhexian.resize();
          zhexian.setOption({
            tooltip: {
              trigger: "axis",
              backgroundColor: "#fff",
              textStyle: {
                color: "#000",
              },
            },

            xAxis: {
              type: "category",
              boundaryGap: false,
              data: arr.map((item) => {
                return item.value;
              }),
              axisLabel: {
                color: "#ffffff",
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                lineStyle: {
                  type: "solid",
                  color: "rgba(0,0,0,.1)",
                },
              },
              axisLabel: {
                color: "#ffffff",
              },
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
            },
            grid: {
              left: "0%",
              right: "2%",
              bottom: "10%",
              containLabel: true,
            },
            series: [
              {
                data: arr.map((item) => {
                  return item.total_money;
                }),
                type: "line",
              },
            ],
          });
        }
      });
    },
    createHaflRoundTask() {
      var banyuanEcharts = echarts.init(document.getElementById("banyuanEcharts"));
      // 指定图表的配置项和数据
      getRankingList({
        ...this.queryForm,
      }).then((res) => {
        this.rankInfo = res.data.data;
      });
      newAchievementReport({
        ...this.queryForm,
      }).then((res) => {
        this.halfInfo = res.data.data;
        var option = {
          series: [
            {
              detail: {
                show: false,
                valueAnimation: true,
              },
              progress: {
                show: true,
                width: 14,
              },
              itemStyle: {
                color: "#2ef8d0", // 用于替代背景色的颜色
                borderColor: "#2ef8d0", // 用跟上面一样的颜色遮住露出的背景色
              },
              type: "gauge",
              startAngle: 180,
              endAngle: 0,
              center: ["50%", "80%"],
              radius: "140%",
              min: 0,
              max: 100,
              splitNumber: 8,
              axisLine: {
                lineStyle: {
                  width: 14,
                  color: [[1, "#068fff"]],
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
              },
              axisLabel: {
                show: false,
                color: "#464646",
              },
              title: {
                show: false,
              },
              data: [
                {
                  // value: 10,
                  value: this.halfInfo.rate,
                },
              ],
            },
          ],
        };

        // 使用刚指定的配置项和数据显示图表。
        banyuanEcharts.setOption(option);
      });
    },
    createRoundProduct() {
      var roundProduct = echarts.init(document.getElementById("roundProduct"));
      getProductStatistics({ ...this.queryForm }).then((res) => {
        let data = res.data.data;
        // 指定图表的配置项和数据
        var option = {
          tooltip: {
            trigger: "item",
          },
          legend: {
            bottom: "5%",
            left: "center",
            textStyle: {
              color: "#ffffff", //字体颜色
            },
            color: "#ffffff",
          },
          series: [
            {
              type: "pie",
              radius: ["30%", "50%"],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 5,
                borderColor: "#dbe5f2",
                borderWidth: 2,
              },
              startAngle: 180,
              label: {
                show: true,
                color: "#31ffff",
                formatter(param) {
                  // correct the percentage
                  return param.name;
                },
              },
              emphasis: {
                label: {
                  show: true,
                  fontWeight: "bold",
                  color: "#ffffff",
                },
              },
              labelLine: {
                show: false,
              },
              data: data.map((item) => {
                return {
                  name: item.product_name,
                  value: item.total,
                };
              }),
            },
          ],
        };

        // 使用刚指定的配置项和数据显示图表。
        roundProduct.setOption(option);
      });
    },
    qufenxi() {
      localStorage.setItem("isSelect", "9-2");
      this.$router.push("/Marketing/Historical_data");
    },
    chanpingfenxi() {
      localStorage.setItem("isSelect", "9-4");
      this.$router.push("/Marketing/prodect_data");
    },
    mianfangwenxi() {
      localStorage.setItem("isSelect", "1-1");
      localStorage.setItem(
        "jumpInfo",
        JSON.stringify({
          human_check: 2,
          company_id: this.company_info.id || "",
          user_name: this.userInfo.name || "",
        })
      );
      this.$router.push("/Marketing/Account");
    },
  },
  watch: {
    serch_val(val) {
      if (val == "") {
        this.serch_compony_list = [];
        this.serch_user_list = [];
        return;
      }
      this.searchCompany();
    },
  },
};
</script>

<style scoped lang="scss">
.datacontent {
  width: 100%;
  height: 100%;
  background-image: url("./../../assets/img/newDataIndex/bigBg.png");
  background-size: cover;
  background-attachment: fixed;
  overflow-y: scroll;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none; /* Chrome Safari */
  }
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  .all_can_see_head_ry {
    padding-top: 0px;
    position: absolute;
    right: 5%;
    color: #fff;
    top: -80px;
    div:first-child {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 27px;
      color: #fff;
      opacity: 1;
    }
    .honer_img_box {
      display: flex;
      height: 78px;
      padding-right: 10px;
      color: #fff;
      align-items: center;
      img {
        width: 32px;
        height: 37px;
        margin-left: 12px;
      }
    }
  }
  .bottomcharts {
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
    flex: 1;
    .zuixindaozhang {
      width: 38.5%;
      border: solid 1px rgba(0, 186, 255, 0.25);
      margin-left: 20px;
      position: relative;
      padding-top: 60px;
      padding-bottom: 10px;
      .table {
        height: 300px;
        overflow-y: scroll;
        margin: 0 auto;
        width: 94%;
        &::-webkit-scrollbar {
          width: 0 !important;
          display: none; /* Chrome Safari */
        }
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        padding-top: 50px;
        .td {
          flex: 1;
          text-align: center;
        }
        .th {
          background: rgba(14, 102, 211, 0.25);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          position: absolute;
          width: 94%;
          top: 60px;
          .td {
            color: #fcba62;
            font-size: 20px;
          }
        }
        .tr-box {
          overflow: scroll;
          height: 265px;
          transition: all 0.3s;
          &::-webkit-scrollbar {
            width: 0 !important;
            display: none; /* Chrome Safari */
          }
          scrollbar-width: none; /* firefox */
          -ms-overflow-style: none; /* IE 10+ */
        }
        .tr {
          background: rgba(14, 102, 211, 0.15);
          margin-top: 3px;
          display: flex;
          padding: 14px 0;
          .td {
            color: #00deff;
            &:nth-child(3) {
              color: #f93f00;
            }
          }
        }
      }
    }
    .numtongji {
      width: 58.5%;
      border: solid 1px rgba(0, 186, 255, 0.25);
      position: relative;
      .border {
        display: block;
        margin: 0 auto;

        margin-top: 1px;
      }
      .bline {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        div {
          flex: 1;
          text-align: center;
          color: #fff;
        }
        font-size: 30px;
        .name {
          font-size: 16px;
          margin-top: 20px;
          margin-bottom: 18px;
        }
        .fk {
          font-size: 20px;
          padding-left: 40px;
          box-sizing: border-box;
        }
        .red,
        .blue {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 20px;
          background-color: #f93f00;
          font-size: 20px;
        }
        .blue {
          background: #2c63bd;
        }
      }
    }
  }
  .big-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .big-box-left {
      flex: 1;
      padding-right: 24px;
      position: relative;
    }
    .big-box-right {
      width: 27%;
      border: solid 1px rgba(0, 186, 255, 0.25);
      position: relative;
      margin-right: 25px;
      #lineEcharts,
      #lineEcharts2 {
        width: 90%;
        height: 348px;
        margin-left: 30px;
      }
      .fg-box {
        padding-top: 40px;
      }
      .line-echarts-box {
        position: relative;
        &:nth-child(2) {
          padding-bottom: 20px;
        }
      }
    }
  }
  .topcharts {
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
    flex: 1;
    .mid {
      width: 58.5%;
      .box {
        border: solid 1px rgba(0, 186, 255, 0.25);
        position: relative;
        margin-top: 10px;
        padding-bottom: 14px;
        #banyuanEcharts {
          width: 400px;
          height: 160px;
          margin: 0 auto;
        }
        .taskover-text {
          position: absolute;
          color: #fff;
          font-size: 14px;
          left: 50%;
          transform: translateX(-50%);
          top: 184px;
        }
        .bfb {
          width: 80px;
          height: 30px;
          border-radius: 5px;
          border: solid 1px #1081ff;
          box-shadow: 0 0 20px #1081ff;
          font-size: 18px;
          color: #fff;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 214px;
          text-shadow: 0 0 10px #0e66d3, 0 0 40px #0e66d3;
        }
        .daqupaiming {
          width: 92%;
          height: 80px;
          border-style: solid;
          border-width: 1px;
          background-color: rgba(14, 102, 211, 0.25);
          border-radius: 44px;
          margin: 0 auto;
          position: relative;
          padding: 0 40px 0 30px;
          .tot {
            color: #fff;
            position: absolute;
            bottom: 10%;
            left: 10%;
          }
          .le {
            font-size: 24px;
            color: #fff;
            span {
              color: #fcba62;
            }
          }
          .mi {
            flex: 1;
            text-align: center;
            font-size: 20px;
            color: #dcdcdc;

            span {
              color: #fcba62;
              cursor: pointer;
            }
            .money {
              color: #42e4fb;
            }
          }
          .ri {
            font-size: 20px;
            color: #dcdcdc;
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              height: 22px;
              margin-left: 20px;
            }
          }
        }
        .tasktongji {
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding-top: 20px;
          div {
            text-align: center;
            .name {
              color: #fff;
              font-size: 24px;
            }
            .value {
              font-size: 36px;
              color: #fff;
              line-height: 36px;
              margin-top: 4px;
              text-shadow: 0 0 10px #0e66d3, 0 0 40px #0e66d3;
            }
          }
        }
      }
      .tits {
        background-image: url("./../../assets/img/newDataIndex/zjbgBg.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 0 center;
        text-align: center;
        font-size: 30px;
        letter-spacing: 2px;
        font-weight: bold;
        color: #fff;
        span {
          background-image: linear-gradient(to top, #a7cdf7, #ffffff); /* 线性渐变背景，方向向上 */
          -webkit-background-clip: text; /* 背景被裁剪成文字的前景色 */
          -webkit-text-fill-color: transparent; /* 文字填充颜色变透明 */
        }
      }
    }

    .left {
      border: solid 1px rgba(0, 186, 255, 0.25);
      width: 38.5%;
      height: 401px;
      margin-left: 20px;
      position: relative;
    }
    #roundProduct {
      width: 100%;
      height: 100%;
    }
  }
  .title {
    background-image: url("./../../assets/img/newDataIndex/titleBg.png");
    width: 100%;
    background-position: center;
    height: 130px;
    text-align: center;
    font-size: 44px;
    color: #fff;
    font-weight: bold;
    line-height: 100px;
    letter-spacing: 10px;
    cursor: pointer;
    img {
      height: 30px;
    }
  }
  .date-select {
    position: absolute;
    left: 20px;
    top: -40px;
    width: 208px;
    height: 36px;
    background-image: url("./../../assets/img/newDataIndex/datebg.png");
    background-size: 100% 100%;
    display: flex;
    .dayselect {
      position: absolute;
      width: 0px;
      left: 0;
      height: 0;
      overflow: hidden;
      height: 36px;
      top: 0;
      opacity: 0;
      transition: all 0.3s;
    }
    .day2 {
      &:hover {
        .dayselect {
          opacity: 1;
          width: 210px;
        }
      }
    }
    .monthselect {
      position: absolute;
      width: 0px;
      right: 0;
      height: 0;
      overflow: hidden;
      height: 36px;
      top: 0;
      opacity: 0;
      transition: all 0.3s;
    }

    .month2 {
      &:hover {
        .monthselect {
          opacity: 1;
          width: 210px;
        }
      }
    }

    div {
      height: 36px;
      display: flex;
      cursor: pointer;
      flex: 1;
      justify-content: center;
      color: #83f4fc;
      align-items: center;
      font-size: 16px;
      img {
        width: 19px;
        margin-right: 4px;
      }
    }
    .active {
      color: #fff;
    }
  }
  .dingwei {
    img {
      position: absolute;
      width: 12px;
      height: 12px;
      &:nth-child(1) {
        left: 0;
        top: 0;
      }
      &:nth-child(2) {
        right: 0;
        top: 0;
      }
      &:nth-child(3) {
        left: 0;
        bottom: 0;
      }
      &:nth-child(4) {
        right: 0;
        bottom: 0;
      }
    }
  }
  .echarts-title {
    position: absolute;
    color: #fff;
    font-size: 20px;
    left: 20px;
    top: 18px;
    &::before {
      content: "";
      background-image: url("./../../assets/img/newDataIndex/titles-right.png");
      position: absolute;
      width: 188px;
      height: 14px;
      left: 120%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .echarts-more {
    position: absolute;
    color: #f3f3f3;
    opacity: 0.7;
    font-size: 16px;
    right: 20px;
    top: 18px;
    cursor: pointer;
    z-index: 2;
  }
  #zhexian {
    width: 98%;
    height: 400px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fg-box {
  height: 20px;
}
.select-cont {
  position: relative;
  .el-icon-error {
    position: absolute;
    font-size: 24px;
    z-index: 2;
    right: 10px;
    top: 8px;
  }
  .lines {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .item {
      margin-top: 5px;
      color: #1081ff;
      cursor: pointer;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
    }
  }
  .line-f {
    margin-top: 10px;
    cursor: pointer;
    .right {
      color: #1081ff;
    }
  }
  .tits {
    margin-top: 20px;
    font-size: 20px;
  }
}
.info_home {
  position: fixed;
  right: 40px;
  bottom: 80px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<style></style>
